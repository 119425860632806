import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TerminalComponent from "./TerminalComponent";

console.log(process.env.REACT_APP_STRIPE_KEY);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeContainer = ({terminal
}) => {

  return <Elements stripe={stripePromise}>{<TerminalComponent terminal={terminal} />}</Elements>;
};

export default StripeContainer;