import React, { Component } from "react";
import "../css/Pledges.css"; // Create a separate CSS file for styling
import RaffleOptions from "./RaffleOptions";
import { faDonate } from "@fortawesome/free-solid-svg-icons";

import { Button, ButtonGroup, Container } from "react-bootstrap";
import { createPaymentIntent, processPayment, checkStatus, finalizePurchase, getAttendeeByPaddleNumber } from "./terminal_service";

const raffleItems = [
  { label: "1 Raffle $25", icon: faDonate, amount: 25, link: "raffle-ticket-1" },
  { label: "3 Raffle Tickets $50", icon: faDonate, amount: 50, link: "raffle-ticket-3" },
  { label: "10 Raffle Tickets $100", icon: faDonate, amount: 100, link: "raffle-ticket-10" },
];

class RaffleTerminal extends Component {
  constructor(props) {
    super(props);


    this.state = {
      ids: "",
      donationType: "",
      customAmount: "",
      dollarAmount: "",
      error: null,
      searchResults: [],
      data: [],
      identifier: "",
      message: props.welcome,
      needMoreInfo: false,
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
      terminal: null,
      reader: null,
      paymentIntentId: "",
      clientSecret: "",
      identifier: "",
      step: 1,
      selectedRaffle: 0,
      quantity: 1
    };

    this.identifierInput = React.createRef();
  }

  handleNoPaddle = () => {
    this.setState({
      ignorePaddle: true,  // Ignore the paddle
      identifier: "",      // Clear the paddle number input
      step: 2,             // Move to the next step
    });
  };

  componentDidUpdate(prevProps) {
    // Check if the selectedType prop has changed
    if (prevProps.welcome !== this.props.welcome) {
      this.reset();
    }
  }

  componentDidMount() {
    // Focus the input element when the component mounts
    if (this.identifierInput.current) {
      this.identifierInput.current.focus();
    }
  }

  getAmount = () => {
    const {selectedRaffle, quantity} = this.state;

    return raffleItems[selectedRaffle].amount * quantity;
  }



  updateMessage = (message, status) => {
    if (status === "success") {
      this.setState({
        message: (
          <span
            className="message success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ),
      });
    } else if (status === "info") {
      this.setState({
        message: (
          <span
            className="message info"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ),
      });
    } else {
      this.setState({
        error: (
          <div className="err404">
            <div className="err404-search">{message}</div>
          </div>
        ),
      });
    }
  };



  handleSubmit_Step_1 = async () => {
    const {terminal, allAttendees} = this.props;
    const {identifier} = this.state;
    const attendee = getAttendeeByPaddleNumber(identifier, allAttendees);

    if (attendee) {
      const paymentIntent = await createPaymentIntent(this.getAmount, terminal, this.updateMessage);
      this.setState({
        firstname: attendee.attendee_firstname,
        lastname: attendee.attendee_lastname,
        phoneNumber: attendee.phone_number,
        email: attendee.email,
      })

      if (paymentIntent) {
        this.updateMessage(`Please verify the purchaser's information.`, "info")


        this.setState(
          {
            clientSecret: paymentIntent.client_secret,
            paymentIntentId: paymentIntent.id,
            step: 2,
          }
        );
      }
    } else {
      this.updateMessage(`We need some more info. Fill in the purchaser's name and phone number`, "info")

      this.setState({
        step: 2,
      });
    }
  };




  handleSubmit_Step_2 = async () => {
    const { paymentIntentId, firstname, lastname, phoneNumber, email, quantity, selectedRaffle } = this.state;
    const { terminal } = this.props;

    if (!firstname || !lastname) {
      this.updateMessage("Please fill in both the first name and last name." , "error")
      return;
    }
  
    if (!phoneNumber) {
      this.updateMessage("Please provide a phone number." , "error")
      return;
    }  

    this.setState({"error": null});
      const paymentIntent = await createPaymentIntent(this.getAmount, terminal, this.updateMessage);

      if (paymentIntent) {
        this.updateMessage(`${terminal.name} is set to $${raffleItems[selectedRaffle].amount}. ${firstname} can tap their credit card.`, "success");
        this.setState(
          {
            clientSecret: paymentIntent.client_secret,
            paymentIntentId: paymentIntent.id,
            step: 3,
          },
          () => this.handleSubmit_Step_3()
        );
      }

  };


  pollPaymentStatus = async () => {
    const pollInterval = 5000; // 5 seconds
    const {terminal, menuItem} = this.props;

    const poll = async () => {
      try {
        const statusResponse = await checkStatus(terminal, this.updateMessage);

        // Log the response for debugging
        console.log(statusResponse);

        // Check if the status is still in progress
        if (statusResponse.action.status === "in_progress") {
          setTimeout(poll, pollInterval); // Poll again after 5 seconds
        } else {
          // Handle success or failure
          if (statusResponse.action.status === "succeeded") {
            this.updateMessage(menuItem.payment_success, "success");
            this.setState({
              step: 4,
            });
          } else {
            this.updateMessage("Payment did not go through. Please try with a different card.", "info");
            this.setState({
              step: 4,
            });
          }
        }
      } catch (error) {
        this.updateMessage(`There is an issue with this device. Go see Apollo`, "error");
      }
    };

    poll(); // Start the polling process
  };

  handleSubmit_Step_3 = async () => {
    const { paymentIntentId } = this.state;
    const {terminal} = this.props;
    try {
      const result = await processPayment(paymentIntentId, terminal, this.updateMessage);

      if (result["status"] !== "success") {
        this.updateMessage(`Cannot proceed with payment processing. Check that ${terminal.name} is online. Click reset and try again`, "error");
      } else {
        this.pollPaymentStatus(); // Start polling the status
      }
    } catch (error) {
      this.updateMessage(`Cannot proceed with payment processing. Check that ${terminal.name} is online. Click reset and try again`, "error");
    }
  };

reset =() => {
  this.setState({
    message: this.props.menuItem.welcome,
    step: 1,
    firstname: "",
    lastname: "",
    phoneNumber: "",
    email: "",
    identifier: "",
    selectedRaffle: 0,
    error: null
  }, ()=> console.log(this.state));
}

  handleSubmit_Step_4 = async () => {
    const { paymentIntentId, firstname, lastname, phoneNumber, email, quantity, selectedRaffle } = this.state;
    const { terminal } = this.props;

    if (!firstname || !lastname) {
      this.updateMessage("Please fill in both the first name and last name." , "error")
      return;
    }
  
    if (!phoneNumber) {
      this.updateMessage("Please provide a phone number." , "error")
      return;
    }  

    const raffleItem = raffleItems[selectedRaffle];

    const response = await finalizePurchase(paymentIntentId, firstname, lastname, phoneNumber, email, quantity, [],
       terminal, raffleItem, this.getAmount, this.updateMessage );


       if (response.status === "success") {
        console.log(response);
        // Generate the summary message
        const raffleNumbers = response?.raffles_info?.map(item => item.RaffleNumber).join(", ");

        let summaryMessage = `
        <ul>
          <li><strong>Item:</strong> ${raffleItem.label}</li>
          <li><strong>Purchaser:</strong> ${firstname} ${lastname}</li>
          <li><strong>Raffle Numbers:</strong> ${raffleNumbers}</li>
      </ul>
      `;
    
        this.updateMessage(summaryMessage, "success");
    
          this.setState({step:5});
      }

  };

  handleSubmit_Step_5 = async () => {
    this.reset();
  };


  handleSubmit = async (e) => {
    e.preventDefault();
    const {step} = this.state;

    this.props.setIsLoading(true);

    if (step === 1) {
      await this.handleSubmit_Step_1();
    } else if (step === 2) {
      await this.handleSubmit_Step_2();
    } else if (step === 4) {
      await this.handleSubmit_Step_4();
    }else if (step === 5) {
      await this.handleSubmit_Step_5();
    }

    this.props.setIsLoading(false);
  };

  handleInputChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  };


  handleReset = async (e) => {
    e.preventDefault();

    this.reset();
  };

  handleSelectedRaffleUpdate = (index) => {
    this.setState({selectedRaffle: index})
  }

  render() {
    const { step, firstname, lastname, phoneNumber, message, error, identifier } = this.state;
    const {terminal, menuItem} = this.props;

    return (
      <>
        <div className="auth-wrap">
          <div className="auth-col pledges">
          <h1 style={{color: "#4b6584"}}>
              <span>
                POS <span style={{color: "#fc5c65"}}>{terminal.name}</span>{" "}
                {menuItem.name}
              </span>
              {this.props.titlePrefix}
            </h1>

            <div className={"message-well" + (step === 5 ? " summary" : "")}>
  {error ? error : message}
</div>

            <Container className="pledge-buttons-container text-right reset">
            <span className="total">Subtotal: ${this.getAmount()}</span>
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={(e) => this.handleReset(e)}
                  className="m-1"
                >
                  Reset
                </Button>
              </ButtonGroup>
            </Container>
            <form className="form-validate" onSubmit={this.handleSubmit}>
              {step === 1 && (
                <>
                  <RaffleOptions
                    selectedRaffleOption={this.state.selectedRaffle}
                    setSelectedRaffle={this.handleSelectedRaffleUpdate}
                    raffleOptions={raffleItems}
                  />
                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">Paddle Number</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="identifier"
                        data-required="text"
                        value={identifier}
                        onChange={(e) =>
                          this.setState({ identifier: e.target.value })
                        }
                      />
                    </span>
                  </div>

                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        className="custom-submit-button"
                        onClick={this.handleNoPaddle} // Update onClick to handle No Paddle
                      >
                        No Paddle?
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>


                </>
              )}

              {step === 2 && (
                <>
                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">First Name</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="firstname"
                        data-required="text"
                        value={firstname}
                        onChange={(e) =>
                          this.handleInputChange("firstname", e.target.value)
                        }
                      />
                    </span>
                  </div>

                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">Last Name</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="lastname"
                        data-required="text"
                        value={lastname}
                        onChange={(e) =>
                          this.handleInputChange("lastname", e.target.value)
                        }
                      />
                    </span>
                  </div>

                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">Phone Number</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="phoneNumber"
                        data-required="text"
                        value={phoneNumber}
                        onChange={(e) =>
                          this.handleInputChange("phoneNumber", e.target.value)
                        }
                      />
                    </span>
                  </div>

                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Purchase
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {step === 4 && (
                <>
                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </>
              )}

{step === 5 && (                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Finish
                      </Button>
                    </div>
                  </div>)}
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default RaffleTerminal;