import React from "react";
import "../css/QuantitySelector.css";

class QuantitySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      currentValue: "",
      editing: false
    };
  }

  handleIncrement = () => {
    const {quantity, handleInputChange} = this.props;

    const newValue = quantity + 1;

    handleInputChange("quantity", newValue);
  };

  handleDecrement = () => {
    const {quantity, handleInputChange} = this.props;

    const newValue = quantity - 1;

    if (newValue > 0) {
        handleInputChange("quantity", newValue);
    }
  };

  handleInputChange = (event) => {
    const newValue = parseInt(event.target.value, 10);

    if (!isNaN(newValue) && newValue > 0) {
      this.setState({ currentValue: newValue });
    }
  };

  handleBlur = (e) => {
    const {handleInputChange} = this.props;
    const {currentValue}  = this.props;
    const newValue = this.state.currentValue;

    if (currentValue) {
        handleInputChange("quantity", newValue);
    }

    this.setState({ editing: false, currentValue: "" });
  };

  getCurrentQuantity = () => {
    if (this.state.editing) {
      return this.state.currentValue;
    }

    return this.props.quantity;
  };

  render() {
    return (
      <>
        <div className="contactform-field contactform-textarea quantity-field">
          <label className="contactform-label">How many {this.props.menuItem.name}s?</label>
          <span className="contactform-input">
            <div className="quantity-control">
              <button
                type="button"
                className="btn btn-outline-secondary btn-left"
                aria-label="Decrement quantity"
                onClick={this.handleDecrement}
              >
                -
              </button>
              <input
                type="number"
                id="quantity-selector"
                className="form-control quantity-input no-spinner"
                value={this.getCurrentQuantity()}
                onFocus={(e) => this.setState({ editing: true })}
                onBlur={this.handleBlur}
                onChange={this.handleInputChange}
              />
              <button
                type="button"
                className="btn btn-outline-secondary btn-right"
                aria-label="Increment quantity"
                onClick={this.handleIncrement}
              >
                +
              </button>
            </div>
          </span>
        </div>
      </>
    );
  }
}

export default QuantitySelector;