import React from "react";
import { Button, ButtonGroup, Container } from 'react-bootstrap';

function RaffleOptions({ selectedRaffleOption, setSelectedRaffle, raffleOptions }) {

  return (
    <>
        <Container className="pledge-buttons-container m-2 text-center">
        <ButtonGroup>
          {raffleOptions.map((option, index) => (
            <Button
              key={index}
              variant={selectedRaffleOption === index ? "primary" : "outline-primary"}
              onClick={() => setSelectedRaffle(index)}
              className="m-1"
            >
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
      </Container>

    </>
  );
}

export default RaffleOptions;