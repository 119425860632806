import axios from "axios";

export const createPaymentIntent = async (
  getAmount,
  terminal,
  updateMessage
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/aura/create-payment-intent`,
      {
        amount: getAmount() * 100, // Convert to cents
        terminal: terminal.id,
      }
    );

    return response.data;
  } catch (error) {
    updateMessage("Payment Failed. Try with a different card.", "error");
    return null;
  }
};

export const processPayment = async (
  paymentIntentId,
  terminal,
  updateMessage
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/aura/process-payment`,
      {
        payment_intent_id: paymentIntentId,
        terminal: terminal.id,
      }
    );

    const responseData = response.data;

    console.log(responseData);
    
    return responseData;
  } catch (error) {
    console.log(error);
    const errorResponse = {
      status: "failed"
    };

    return errorResponse;
  }
};

export const checkStatus = async (terminal, updateMessage) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/aura/status?terminal=${terminal.id}`
    );

    return response.data;
  } catch (error) {
    this.updateMessage(`There is an issue with this device. Go see Apollo`, "error");
  }
};

export const finalizePurchase = async (
  paymentIntentId,
  firstname,
  lastname,
  phoneNumber,
  email,
  quantity,
  corks,
  terminal,
  menuItem,
  getAmount,
  updateMessage
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/aura/finalize-purchase`,
      {
        payment_intent_id: paymentIntentId,
        terminal: terminal.id,
        item: menuItem.link,
        firstname: firstname,
        lastname: lastname,
        phoneNumber: phoneNumber,
        email: email,
        quantity: quantity,
        corks: corks,
        amount: getAmount(),
        originURL: window.location.origin
      }
    );

    return response.data;
  } catch (error) {
    updateMessage(`There is an issue with this device. Go see Apollo`, "error");
  }
};


export const allCheckins = async () => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/all-checkins`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        return false;
      }

      const responseBody = response.json();

      return responseBody;
    } catch (err) {
      return false;
    }
}

export const getAttendeeByPaddleNumber = (identifier, allAttendees) => {
  if (!identifier) {
    return null;
  }
  
  const attendee = allAttendees.find(att => `${att.paddle_number}` === `${identifier}`);
  return attendee || null;
};