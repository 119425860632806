import React, { useState, useEffect } from "react";
import "../css/Pledges.css";
import LogoHeader from "./LogoHeader";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { default as Terminal } from "./Terminal";
import { default as RaffleTerminal } from "./RaffleTerminal";
import { faDisplay, faList, faDollar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { allCheckins } from "./terminal_service";

const weclome_message_teddy_bear = (<>
  <span className="message success">
    READY:&nbsp;
  </span>
    <span className="message welcome">
    Select the quantity then scan purchaser's ticket.<br/>
  </span>
</>

);

const weclome_message_bracelet = (<>
  <span className="message success">
  READY:&nbsp;
  </span>
    <span className="message welcome">
    Select the quantity then scan purchaser's ticket.<br/>
  </span>
</>

);

const weclome_message_wine = (<>
  <span className="message success">
  READY:&nbsp;
  </span>
    <span className="message welcome">
    Select the quantity then scan purchaser's ticket.<br/>
  </span>
</>

);


const weclome_message_raffle = (<>
  <span className="message success">
  READY:&nbsp;
  </span>
    <span className="message welcome">
    Select the raffle package option then enter purchaser's information.
  </span>
</>

);

const terminalOptions = [
  {link: "Terminal_A", id: "tmr_FrHUtAi5tCDHRj", name: "Terminal A"},
  {link: "Terminal_B", id: "tmr_FrIysg9RPriD5w", name: "Terminal B"}
]

const menuItems = [
  {
    label: "Teddy Bears",
    link: "teddybears",
    icon: faDollar,
    welcome: weclome_message_teddy_bear,
    name: "Teddy Bear",
    amount: 30,
    payment_success: "Payment successful!"
  },
  {
    label: "Bracelets",
    link: "bracelets",
    icon: faDollar,
    welcome: weclome_message_bracelet,
    name: "Bracelet",
    amount: 85,
    payment_success: "Payment successful!"
  },
  {
    label: "Wine",
    link: "wine",
    icon: faList,
    welcome: weclome_message_wine,
    name: "Wine Cork",
    amount: 30,
    payment_success: "Payment successful! Please enter the wine cork numbers"
  },
  {
    label: "Raffle",
    link: "raffle",
    icon: faList,
    welcome: weclome_message_raffle,
    name: "Raffle",
    amount: 25,
    payment_success: "Payment successful!"
  },
];



const TerminalComponent = ({terminal}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [terminalIndex, setTerminalIndex] = useState(() => {

    const savedTerminalIndex = localStorage.getItem('terminalIndex');

    return savedTerminalIndex !== null ? parseInt(savedTerminalIndex, 10) : 0;
  });

  const isTest = window.location.hostname.includes('test.');
  const [titleSuffix, setTitleSuffix] = useState("")

  const [pageIndex, setPageIndex] = useState(() => {
    const savedPageIndex = localStorage.getItem('pageIndex');
    return savedPageIndex !== null ? parseInt(savedPageIndex, 10) : 0;
  });

const [allAttendees, setAllAttendees] = useState([]);

 const preloadAttendees = async () => {
    setIsLoading(true);
    const allAttendees = await allCheckins();

    setAllAttendees(allAttendees);
    setIsLoading(false);

  }

  useEffect(() => {
    preloadAttendees();

    const params = new URLSearchParams(location.search);
    const page = params.get('page');

    if (isTest) {
      setTitleSuffix(<> [Staff Only, Test]</>)
    } else {
      setTitleSuffix(<> [Staff Only]</>)
    }

    if (page || terminal) {
      const menuItemIndex = menuItems.findIndex(item => item.link === page);
      const terminalIndex = terminalOptions.findIndex(item => item.link === terminal);

      if (menuItemIndex !== -1) {
        setPageIndex(menuItemIndex);
      }

      if (terminalIndex !== -1) {
        setTerminalIndex(terminalIndex);
      }

      params.delete('page');
      params.delete('terminal');
      const newUrl = `${location.pathname}`;
      window.history.replaceState({}, '', newUrl);
    } else {
      const savedPageIndex = localStorage.getItem('pageIndex');
      
      setPageIndex(savedPageIndex !== null ? parseInt(savedPageIndex, 10) : 0);
    }

    
  }, [location.search, navigate, location.pathname]);


  useEffect(() => {
    localStorage.setItem('pageIndex', pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    localStorage.setItem('terminalIndex', terminalIndex);
  }, [terminalIndex]);

const getWelcome = () => {
  return menuItems[pageIndex].welcome;
}

  return (
    <>
      <LogoHeader
        selectedType={pageIndex}
        setSelectedType={setPageIndex}
        menuItems={menuItems}
      />
      <main className="add-pledges">
        <section className="container stylization maincont">
          {(pageIndex === 0 ||
            pageIndex === 1 || 
            pageIndex === 2) && (
            <Terminal
              setIsLoading={setIsLoading}
              toast={toast}
              menuItem={menuItems[pageIndex]}
              titlePrefix={titleSuffix}
              terminal={terminalOptions[terminalIndex]}
              welcome={getWelcome()}
              allAttendees={allAttendees}
            />
          )}

          {pageIndex === 3 && (
            <RaffleTerminal
              setIsLoading={setIsLoading}
              toast={toast}
              menuItem={menuItems[pageIndex]}
              titlePrefix={titleSuffix}
              terminal={terminalOptions[terminalIndex]}
              welcome={getWelcome()}
              allAttendees={allAttendees}
            />
          )}
        </section>

        <ToastContainer />
        {isLoading && <Spinner />}
      </main>
    </>
  );
};

export default TerminalComponent;