import React, { Component } from "react";
import "../css/Pledges.css"; // Create a separate CSS file for styling
import QuantitySelector from "./QuantitySelector";
import {
  createPaymentIntent,
  processPayment,
  checkStatus,
  finalizePurchase,
  getAttendeeByPaddleNumber
} from "./terminal_service";

import { Button, ButtonGroup, Container } from "react-bootstrap";

class Terminal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      message: props.welcome,
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
      terminal: null,
      reader: null,
      clientSecret: "",
      paymentIntentId: "",
      identifier: "",
      step: 1,
      quantity: 1,
      corks: [],
      ignorePaddle: false, // New state variable
    };

    this.identifierInput = React.createRef();
  }

  handleNoPaddle = () => {
    this.updateMessage(
      `We need some more info. Fill in the purchaser's name and either the email OR phone number`,
      "info"
    );
    
    this.setState({
      ignorePaddle: true,  // Ignore the paddle
      identifier: "",      // Clear the paddle number input
      step: 2,             // Move to the next step
    });
  };

  componentDidUpdate(prevProps) {
    // Check if the selectedType prop has changed
    if (prevProps.welcome !== this.props.welcome) {
      this.reset();
    }
  }

  componentDidMount() {
    // Focus the input element when the component mounts
    if (this.identifierInput.current) {
      this.identifierInput.current.focus();
    }
  }

  getAmount = () => {
    return this.props.menuItem.amount * this.state.quantity;
  };

  updateMessage = (message, status) => {
    if (status === "success") {
      this.setState({
        message: (
          <span
            className="message success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ),
      });
    } else if (status === "info") {
      this.setState({
        message: (
          <span
            className="message info"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ),
      });
    } else {
      this.setState({
        error: (
          <div className="err404">
            <div className="err404-search">{message}</div>
          </div>
        ),
      });
    }
  };
  

  handleSubmit_Step_1 = async () => {
    const { terminal, allAttendees } = this.props;
    const { identifier, ignorePaddle } = this.state;

    if (!ignorePaddle && identifier) { 
      const attendee = getAttendeeByPaddleNumber(identifier, allAttendees);
      
      if (attendee) {
        try {
          const paymentIntent = await createPaymentIntent(
            this.getAmount,
            terminal,
            this.updateMessage
          );
          this.setState({
            firstname: attendee.attendee_firstname,
            lastname: attendee.attendee_lastname,
            phoneNumber: attendee.phone_number,
            email: attendee.email,
          });

          if (paymentIntent) {
            this.updateMessage(
              `Please verify the purchaser's information.`,
              "info"
            );

            this.setState({
              clientSecret: paymentIntent.client_secret,
              paymentIntentId: paymentIntent.id,
              step: 2,
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.updateMessage(
          `We need some more info. Fill in the purchaser's name and either the email OR phone number`,
          "info"
        );

        this.setState({
          step: 2,
        });
      }
    } else {
      // No Paddle case or empty identifier
      this.updateMessage(
        `We need some more info. Fill in the purchaser's name and either the email OR phone number`,
        "info"
      );
      this.setState({
        step: 2,
      });
    }
  };

  handleSubmit_Step_2 = async () => {
    const { firstname, lastname, phoneNumber, email } = this.state;
    const { terminal } = this.props;

    if (!firstname || !lastname) {
      this.updateMessage(
        "Please fill in both the first name and last name.",
        "error"
      );
      return;
    }

    if (!phoneNumber && !email) {
      this.updateMessage(
        "Please provide either a phone number or an email address.",
        "error"
      );
      return;
    }
    this.setState({ error: null });

    const paymentIntent = await createPaymentIntent(
      this.getAmount,
      terminal,
      this.updateMessage
    );

    if (paymentIntent) {
      this.updateMessage(
        `${this.props.terminal.name} is ready. ${this.state.firstname} can tap their credit card.`,
        "success"
      );
      this.setState(
        {
          clientSecret: paymentIntent.client_secret,
          paymentIntentId: paymentIntent.id,
          step: 3,
        },
        () => this.handleSubmit_Step_3()
      );
    }
  };

  pollPaymentStatus = async () => {
    const pollInterval = 5000; // 5 seconds
    const { terminal, menuItem } = this.props;

    const poll = async () => {
      try {
        const statusResponse = await checkStatus(terminal, this.updateMessage);

        // Log the response for debugging
        console.log(statusResponse);

        // Check if the status is still in progress
        if (statusResponse.action.status === "in_progress") {
          setTimeout(poll, pollInterval); // Poll again after 5 seconds
        } else {
          // Handle success or failure
          if (statusResponse.action.status === "succeeded") {
            this.updateMessage(
              menuItem.payment_success,
              "success"
            );
            this.setState({
              step: 4,
            });
          } else {
            this.updateMessage(
              "Payment did not go through. Please try with a different card.",
              "error"
            );
            this.setState({
              step: 4,
            });
          }
        }
      } catch (error) {
        this.updateMessage(
          `There is an issue with this device. Go see Apollo`,
          "error"
        );
      }
    };

    poll();
  };

  handleSubmit_Step_3 = async () => {
    const { paymentIntentId } = this.state;
    const { terminal } = this.props;
    try {
      const result = await processPayment(
        paymentIntentId,
        terminal,
        this.updateMessage
      );

      if (result["status"] !== "success") {
        this.updateMessage(
          `Cannot proceed with payment processing. Check that ${terminal.name} is online. Click reset and try again`,
          "error"
        );
      } else {
        this.pollPaymentStatus();
      }
    } catch (error) {
      this.updateMessage(
        `Cannot proceed with payment processing. Check that ${terminal.name} is online. Click reset and try again`,
        "error"
      );
    }
  };

  handleSubmit_Step_4 = async () => {
    const {
      paymentIntentId,
      firstname,
      lastname,
      phoneNumber,
      email,
      quantity,
      corks,
    } = this.state;
    const { terminal, menuItem } = this.props;

    const response = await finalizePurchase(
      paymentIntentId,
      firstname,
      lastname,
      phoneNumber,
      email,
      quantity,
      corks,
      terminal,
      menuItem,
      this.getAmount,
      this.updateMessage
    );

    if (response.status === "success") {
    // Generate the summary message
    let summaryMessage = `
    <ul>
      <li><strong>Item:</strong> ${menuItem.name}</li>
      <li><strong>Quantity:</strong> ${quantity}</li>
  `;

  if (menuItem.link.includes("wine")) {
    summaryMessage += `<li><strong>Corks:</strong> ${corks.join(", ")}</li>
    <li><strong>Purchaser:</strong> ${firstname} ${lastname}</li>
  </ul>`;
  } else {
    summaryMessage += `<li><strong>Purchaser:</strong> ${firstname} ${lastname}</li>
  </ul>`;
  }

    this.updateMessage(summaryMessage, "success");

      this.setState({step:5});
    }
  };


  handleSubmit_Step_5 = async () => {
    this.reset();
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { step } = this.state;
    this.props.setIsLoading(true);

    if (step === 1) {
      await this.handleSubmit_Step_1();
    } else if (step === 2) {
      await this.handleSubmit_Step_2();
    } else if (step === 4) {
      await this.handleSubmit_Step_4();
    }else if (step === 5) {
      await this.handleSubmit_Step_5();
    }

    this.props.setIsLoading(false);
  };

  handleInputChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  reset = () => {
    this.setState({
      message: this.props.menuItem.welcome,
      step: 1,
      firstname: "",
      lastname: "",
      phoneNumber: "",
      email: "",
      identifier: "",
      quantity: 1,
      corks: [],
      error: null,
      ignorePaddle: false
    });
  };

  handleReset = async (e) => {
    e.preventDefault();

    this.reset();
  };

  render() {
    const {
      step,
      firstname,
      lastname,
      phoneNumber,
      email,
      quantity,
      message,
      identifier,
      error,
      corks,
    } = this.state;
    const { terminal, menuItem, titlePrefix } = this.props;

    return (
      <>
        <div className="auth-wrap">
          <div className="auth-col pledges">
            <h1 style={{ color: "#4b6584" }}>
              <span>
                POS <span style={{ color: "#fc5c65" }}>{terminal.name}</span>{" "}
                {menuItem.name}
              </span>
              {titlePrefix}
            </h1>

            <div className={"message-well" + (step === 5 ? " summary" : "")}>
  {error ? error : message}
</div>

            <Container className="pledge-buttons-container text-right reset">
              <span className="total">Subtotal: ${this.getAmount()}</span>
              <ButtonGroup>
                <Button
                  variant="primary"
                  onClick={(e) => this.handleReset(e)}
                  className="m-1"
                >
                  Reset
                </Button>
              </ButtonGroup>
            </Container>

            <form className="form-validate" onSubmit={this.handleSubmit}>
              {step === 1 && (
                <>
                  <QuantitySelector
                    quantity={quantity}
                    handleInputChange={this.handleInputChange}
                    menuItem={menuItem}
                  />
                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">Paddle Number</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="identifier"
                        data-required="text"
                        value={identifier}
                        onChange={(e) =>
                          this.setState({ identifier: e.target.value })
                        }
                      />
                    </span>
                  </div>
                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        className="custom-submit-button"
                        onClick={this.handleNoPaddle} // Update onClick to handle No Paddle
                      >
                        No Paddle?
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">First Name</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="firstname"
                        data-required="text"
                        value={firstname}
                        onChange={(e) =>
                          this.handleInputChange("firstname", e.target.value)
                        }
                      />
                    </span>
                  </div>

                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">Last Name</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="lastname"
                        data-required="text"
                        value={lastname}
                        onChange={(e) =>
                          this.handleInputChange("lastname", e.target.value)
                        }
                      />
                    </span>
                  </div>

                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">Phone Number</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="phoneNumber"
                        data-required="text"
                        value={phoneNumber}
                        onChange={(e) =>
                          this.handleInputChange("phoneNumber", e.target.value)
                        }
                      />
                    </span>
                  </div>

                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">Email</label>
                    <span className="contactform-input">
                      <input
                        className="pledge-input"
                        type="text"
                        name="email"
                        data-required="text"
                        value={email}
                        onChange={(e) =>
                          this.handleInputChange("email", e.target.value)
                        }
                      />
                    </span>
                  </div>

                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Purchase
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {step === 4 && (
                <>
                  {menuItem.link === "wine" && (
                    <>
                      {Array.from({ length: quantity }).map((_, index) => (
                        <div
                          className="contactform-field contactform-textarea"
                          key={index}
                        >
                          <label className="contactform-label">
                            Cork Number {index + 1}
                          </label>
                          <span className="contactform-input">
                            <input
                              className="pledge-input"
                              type="text"
                              name={`cork-${index}`}
                              data-required="text"
                              value={corks[index] || ""}
                              onChange={(e) =>
                                this.setState((prevState) => {
                                  const corks = [...prevState.corks];
                                  corks[index] = e.target.value;
                                  return { corks };
                                })
                              }
                            />
                          </span>
                        </div>
                      ))}
                    </>
                  )}

                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </>
              )}

{step === 5 && (                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Finish
                      </Button>
                    </div>
                  </div>)}
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Terminal;